import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import MyLogo from "../components/Logo"
import MySignature from "../images/Signature.svg"

import "../Style/EssayPage.css"
import TitleUnderlined from "../components/TitleUnderlined"
import MyLogoFixed from "../components/LogoFixed"

const EssayPreset = ({data}) => (
 <div> 
    <SEO title="Essay Preset — Phil Murphy" />

    <Helmet>
       <meta name="robots" content="noindex" />
       <link rel="subresource" href="../Style/EssayPage.css" />
    </Helmet>

    <div className="EssayPageWrapper">

        <essayheader><MyLogoFixed /></essayheader>

        <essaycontent>

        <div className="CautionBlock">
          <div className="CautionContent">
          <div className="CautionStyle">This is not a final/public version of the upcoming essay! If you get to this page, please do not share it until it's released.</div>
          </div>
        </div>

        

          <p className="EssayTitlePage">Essay Preset</p>
          <p className="EssayContentPage">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo<sup className="SupContent"><a href="#gg">[1]</a></sup>. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
          </p>

          <p className="EssayAddContentPage">Additional content title</p>

          <br />

          <TitleUnderlined title="Red title" color="red" />


        <p className="EssayContentPage" id="gg">
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi <span className="TextHighlight">architecto beatae vitae dicta sunt explicabo</span>. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
        </p>

        <TitleUnderlined title="Orange title" color="orange" />

        <div className="CautionBlock">
          <h2 className="CautionTitle">Author's note:</h2>
          <div className="CautionContent">
          <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
          </div>
        </div>

        <TitleUnderlined title="Green title" color="green" />

        <div className="QuoteBlockPurple">
          <div className="QuoteContent"><span className="QuoteHighlight">“Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.”</span></div>
          <p className="QuoteOwner">— <a href="https://twitter.com/NERFWRLD" target="_blank" rel="noopener"><b>Phil Murphy</b></a>, Founder of FrameNinja</p>
        </div>

        <div className="Contentnderline"></div>

        <TitleUnderlined title="Yellow title" color="yellow" />

        <div className="ListBox">
            <p className="ListTitleRed">Red list</p>
            <ul>
            <li>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.</li>
                <li>Sed ut perspiciatis unde omnis iste natus error sit doloremque laudantium.</li>
                <li>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque</li>
            </ul>
        </div>

        <p className="EssaySecondHeaderTypeRed">Another title red style</p>

        <div className="ListBox">
            <p className="ListTitlePurple">Purple list</p>
            <ul>
                <li>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.</li>
                <li>Sed ut perspiciatis unde omnis iste natus error sit doloremque laudantium.</li>
                <li>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque</li>
            </ul>
        </div>

        <div className="Contentnderline"></div>

        <p className="EssayAddContentPage">YouTube Embed</p>
       <div className="EmbedVideo">
          <iframe src="https://www.youtube.com/embed/nBtDsQ4fhXY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>

        <div className="Contentnderline"></div>

        <p className="EssaySecondHeaderTypePurple">Another title purple style</p>


         <p className="EssayAddContentPage">Normal image</p>

        <div className="EssayImagePage">
          <a href="../static/a9c7d13f6bd851f1b5db7cf80c0a83b0/ec009/WOW.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.WOW.childImageSharp.fluid}
            />
          </a>
        </div>

        <div className="Contentnderline"></div>

        <p className="EssayAddContentPage">Twitter image</p>

        <div className="EssayTwitterImagePage">
          <a href="https://twitter.com/FrameNinjaHQ/status/1130289067357364225?s=20" target="_blank" rel="noopener">
            <Img 
                fluid={data.Tweet1.childImageSharp.fluid}
            />
          </a>
        </div>

        <div className="Contentnderline"></div>


        <div className="CautionBlock">
          <h2 className="CautionTitle">Thanks for scrolling down here!</h2>
          <div className="CautionContent">
          <div className="CautionStyle">Huge thanks to all...</div>

          <div className="CautionStyle">If you did like it and wondering where you can find more, the answer is my <a href="https://twitter.com/NERFWRLD" target="_blank" rel="noopener">Twitter</a>.</div>
          
          <div className="CautionStyle">And... I don't really like the "subscription button", so if you want to get personal email from me every time when I publish something worth-reading, you can shoot me a message <b>philmurphy@hey.com</b> and I will start sending you personal messages.</div>

          <div className="CautionStyle">You can also send me a message via email or Twitter DMs if you have any questions or you want to discuss topic I have covered in that essay. Always open and happy!</div>
          </div>
        </div>
  

        </essaycontent>







        <essayfooter>
        <div>
          <div className="Signature"><img src={MySignature} width="166" /></div>
        </div>
        </essayfooter>

    </div>

    </div>
)

export default EssayPreset

export const query = graphql`
  query {
  
      Tweet1: file(relativePath: { eq: "Tweet1.png" }) {
          childImageSharp {
            fluid(maxWidth: 3000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }

      WOW: file(relativePath: { eq: "WOW.png" }) {
         childImageSharp {
            fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

  }
`